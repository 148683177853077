<template>
  <div class="details">
    <div class="top-tabs">
      <router-link
        replace
        class="tab"
        :to="{ name: tab.name, query: { bid: bid } }"
        v-for="(tab, index) in topTabs"
        :key="index"
      >
        <span>{{ tab.text }}</span>
      </router-link>
    </div>
    <div class="main">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      bid: '',
      topTabs: [{
        name: 'EntryRecord',
        text: '录入记录'
      }, {
        name: 'SupplierList',
        text: '供应商列表'
      }]
    }
  },
  created () {
    // console.log(this.$route.query.bid)
    this.bid = this.$route.query.bid
    this.$router.push({
      name: 'EntryRecord',
      query: {
        bid: this.$route.query.bid
      }
    })
      .catch(() => {
        console.log('err :路由重复')
      })
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
